import moment from 'moment';
import { CaseData } from './interfaces/CaseData';
import { DataTableColumn } from 'components/DataTable';
import { DynamicFieldType } from 'components/Dynamic';
import { DynamicQueryFieldType } from 'components/Dynamic/DynamicSearchBox/types';
import { StyledTextLink } from 'components/DataTable/DataTable.styled';
import {
  RbpCaseStatus,
  RbpCaseStatusDescription,
  RbpCaseStatusGroup,
  TransientRbpCaseStatuses,
} from 'core/enums/RbpCaseStatus';
import { errorCodeLabels } from './ErrorCodeLabel';
import { Box, Tooltip, Typography } from '@mui/material';
import { TooltipCategories } from 'components/Form/FormInputTooltip';
import AppColors from 'AppColors';
import { CaseSearchType, CaseSearchTypeDetails } from './CaseSearchTypeSelector';
import { CANADA_STATES, US_STATES } from 'utils/states';
import HourglassBottomTwoTone from '@mui/icons-material/HourglassBottomTwoTone';
import Close from '@mui/icons-material/Close';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { BulkActionStatus } from 'core/enums/BulkActionStatus';
import { useFeaturePermission } from 'auth';

export type CaseSearch = {
  rapCaseId?: string;
  rapCallId?: string;
  rapProgramCode?: string;
  clubCode?: string;
  programName?: string;
  vehicleVin?: string;
  clientName?: string;
  callStatus?: string;
  rbpCaseStatus?: string | string[];
  errorCode?: string;
  errorCodeStatus?: string;
  rapCallCreatedDateTime?: string | string[];
  rapCallClosedDateTime?: string | string[];
  'breakdownLocation/state'?: string;
};

export const CaseSearchInitialValues: CaseSearch = {
  rapCaseId: '',
  rapCallId: '',
  rapProgramCode: '',
  clubCode: '',
  programName: '',
  vehicleVin: '',
  clientName: '',
  callStatus: '',
  rbpCaseStatus: '',
  errorCode: '',
  errorCodeStatus: '',
  rapCallCreatedDateTime: '',
  'breakdownLocation/state': '',
};

export const CaseSearchFields = (
  caseSearchType: CaseSearchType,
  enabledStartDate = false,
): DynamicQueryFieldType<CaseSearch>[] => [
  caseSearchType === CaseSearchType.CaseId
    ? {
        name: CaseSearchTypeDetails[CaseSearchType.CaseId].filterField as keyof CaseSearch,
        label: CaseSearchTypeDetails[CaseSearchType.CaseId].label,
        searchType: 'in',
        searchTransformer: (value) =>
          value
            .toString()
            .split(/[\s,-]+/)
            .filter(Boolean),
        type: DynamicFieldType.TEXT,
      }
    : {
        name: CaseSearchTypeDetails[CaseSearchType.CallKey].filterField as keyof CaseSearch,
        label: CaseSearchTypeDetails[CaseSearchType.CallKey].label,
        searchType: 'like',
        searchTransformer: (value) =>
          value.toString().replaceAll('-', '').replace(/^0+/, '').trim(),
        type: DynamicFieldType.TEXT,
      },
  {
    name: 'rapProgramCode',
    label: 'RAP Program Code',
    searchType: 'equal',
  },
  {
    name: 'clubCode',
    label: 'Club Code',
    searchType: 'in',
    searchTransformer: (value) => {
      return value.toString().split(',');
    },
    type: DynamicFieldType.TEXT,
    tooltip: TooltipCategories.errorsSeparatedByCommas,
  },
  {
    name: 'vehicleVin',
    label: 'VIN',
    searchType: 'like',
  },
  {
    name: 'programName',
    label: 'Program Name',
    searchType: 'like',
  },
  {
    name: 'clientName',
    label: 'Client Name',
    searchType: 'like',
  },
  {
    name: 'rapCallCreatedDateTime',
    label: 'Call Date',
    searchType: 'dateRange',
    type: DynamicFieldType.RANGE_DATE,
    // Force the start date to the first day of the month - Requested on https://nationalaaa.atlassian.net/browse/RBP-2200
    isOutsideRange: enabledStartDate
      ? (day: moment.Moment) => day.isBefore(moment('2023-11-01'))
      : undefined,
  },
  {
    name: 'rapCallClosedDateTime',
    label: 'Call Closed Date',
    searchType: 'dateRange',
    type: DynamicFieldType.RANGE_DATE,
  },
  {
    name: 'callStatus',
    label: 'RAP Call Status',
    searchType: 'like',
  },
  {
    name: 'rbpCaseStatus',
    label: 'RBP Case Status',
    searchType: 'in',
    type: DynamicFieldType.SELECT,
    select: {
      options: Object.entries(RbpCaseStatusDescription).map(([value, label]) => ({
        label,
        value,
        group: TransientRbpCaseStatuses.includes(value as RbpCaseStatus)
          ? RbpCaseStatusGroup.TRANSIENT
          : RbpCaseStatusGroup.PRIMARY,
      })),
      multiple: true,
    },
  },
  {
    name: 'errorCode',
    label: 'Error Code',
    type: DynamicFieldType.TEXT,
    searchFor: 'errors.code',
    searchType: 'in',
    searchTransformer: (value) => {
      return value.toString().split(',');
    },
    tooltip: TooltipCategories.errorsSeparatedByCommas,
  },
  {
    name: 'errorCodeStatus',
    label: 'Error Code Status',
    type: DynamicFieldType.SELECT,
    searchFor: 'errors.resolutionType',
    searchType: 'exists-equal',
    select: {
      options: [
        { value: '', label: 'Show All' },
        { value: 'PENDING', label: 'Pending' },
        { value: 'RESOLVED', label: 'Resolved' },
      ],
    },
  },
  {
    name: 'breakdownLocation/state',
    label: 'State / Province',
    searchType: 'in',
    type: DynamicFieldType.SELECT,
    select: {
      options: [...US_STATES, ...CANADA_STATES],
      multiple: true,
    },
  },
];

export const CaseDataTableConfig: (showBulkActions: boolean) => DataTableColumn<CaseData>[] = (
  showBulkActions,
) => {
  const columns: DataTableColumn<CaseData>[] = [
    {
      label: 'RAP Case Id',
      id: 'rapCaseId',
      accessor: (row: CaseData) => {
        return <StyledTextLink variant="body2">{row.rapCaseId}</StyledTextLink>;
      },
      sortable: true,
    },
    { id: 'rapProgramCode', label: 'RAP Program Code', sortable: true },
    { id: 'clientName', label: 'Client Name', sortable: true },
    { id: 'programName', label: 'Program Name', sortable: true },
    { id: 'clubCode', label: 'Club Code', sortable: true },
    { id: 'rapCallCreatedDateTime', label: 'Call Date', sortable: true },
    { id: 'rapCallClosedDateTime', label: 'Call Close Date', sortable: true },
    {
      id: 'callStatus',
      label: 'RAP Call Status',
      sortable: true,
    },
    {
      id: 'rbpCaseStatus',
      label: 'RBP Case Status',
      accessor: (row) =>
        row.rbpCaseStatus === RbpCaseStatus.CANCELLED ? (
          <Typography component={'span'} fontWeight={700} color={AppColors.AAA_RED}>
            {RbpCaseStatusDescription[row.rbpCaseStatus]}
          </Typography>
        ) : (
          RbpCaseStatusDescription[row.rbpCaseStatus]
        ),
      sortable: true,
    },
    {
      id: 'errorCode' as keyof CaseData,
      label: 'Error Code',
      sortable: false,
      accessor: (row) => (
        <Box flexDirection="row" display="flex">
          {row.caseErrors.map((caseError, index) => {
            const { code: errorCode } = caseError;

            return (
              <Tooltip key={errorCode} title={errorCodeLabels[errorCode] || ''}>
                <Typography
                  variant="body1"
                  textOverflow="ellipsis"
                  maxWidth="300px"
                  overflow="hidden"
                >
                  {errorCode}
                  {index < row.caseErrors.length - 1 ? ', ' : ''}
                </Typography>
              </Tooltip>
            );
          })}
        </Box>
      ),
    },
  ];

  if (showBulkActions) {
    columns.push({
      id: 'bulkActionStatus',
      label: 'Bulk Action Status',
      sortable: true,
      accessor: (row) => (
        <>
          {row.bulkActionStatus === BulkActionStatus.PENDING && (
            <Box display="flex" justifyContent="flex-end">
              <Typography paddingRight="6px">PENDING</Typography>
              <HourglassBottomTwoTone sx={{ color: 'brown' }} />
            </Box>
          )}
          {row.bulkActionStatus === BulkActionStatus.FAILED && (
            <Box display="flex" justifyContent="flex-end">
              <Typography paddingRight="6px">FAILED</Typography>
              <Close sx={{ color: 'red' }} />
            </Box>
          )}
          {row.bulkActionStatus === BulkActionStatus.SUCCESS && (
            <Box display="flex" justifyContent="flex-end">
              <Typography paddingRight="6px">SUCCESS</Typography>
              <CheckCircle sx={{ color: 'green' }} />
            </Box>
          )}
        </>
      ),
    });
  }

  return columns;
};

export const BULK_ACTIONS_PERMISSION = 'BulkActionDto';

export function useBulkActionsPermissions() {
  return {
    permissions: useFeaturePermission({ table: BULK_ACTIONS_PERMISSION }),
  };
}
